<template>
    <div class="warp" id="user_info">
        <el-row class="card">
            <el-col>
                <h1 class="text-center">
                    个人信息
                </h1>
            </el-col>
            <el-col>
                <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                    <!-- <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item label="头像">
                            <el-upload class="avatar-uploader" drag accept="image/gif, image/jpeg, image/png, image/jpg"
                                action="" :http-request="uploadAvatar" :show-file-list="false">
                                <img v-if="form.avatar" :src="$fullUrl(form.avatar)" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item label="用户名" prop="username">
                            {{obj.username}}
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item prop="nickname" label="警号">
                            <el-input v-model="form.nickname" :minlength="0" :maxlength="16" placeholder=""  :disabled="true"/>
                        </el-form-item>
                    </el-col> -->

                    <!-- <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item label="用户组" prop="user_group">
                            {{obj.user_group}}
                        </el-form-item>
                    </el-col> -->

                    <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item prop="phone" label="身高">
                            <el-input v-model="form.phone" :minlength="0" :maxlength="11"
                                placeholder="身高(cm)" />
                        </el-form-item>
                    </el-col>
                    
                    <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item prop="email" label="体重">
                            <el-input v-model="form.email" :minlength="0" :maxlength="64"
                                placeholder="体重(kg)" />
                        </el-form-item>
                    </el-col>
                    
                    <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="上衣尺码" prop="phone_state">
					<el-select v-model="form.phone_state" placeholder="请选择">
						<el-option v-for="group in list_state" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="单裤尺码" prop="email_state">
					<el-select v-model="form.email_state" placeholder="请选择">
						<el-option v-for="group in list_state" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="腰带尺码" prop="yd_state">
					<el-select v-model="form.yd_state" placeholder="请选择">
						<el-option v-for="group in list_state3" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="衬衣尺码" prop="cy_state">
					<el-select v-model="form.cy_state" placeholder="请选择">
						<el-option v-for="group in list_state4" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="帽子尺码" prop="mz_state">
					<el-select v-model="form.mz_state" placeholder="请选择">
						<el-option v-for="group in list_state2" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
				<el-form-item label="鞋子尺码" prop="xz_state">
					<el-select v-model="form.xz_state" placeholder="请选择">
						<el-option v-for="group in list_state1" :key="group.value" :label="group.name"
							:value="group.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>



                    <el-col :xs="24" :sm="12" :lg="8" class="el_form_item_warp">
                        <el-form-item prop="price" label="可用资金">
                            <el-input v-model="form.price" :minlength="0" :maxlength="64"
                                placeholder="金额"  :disabled="true"/>
                        </el-form-item>
                    </el-col>

                    

                    <el-col :xs="24" :sm="12" :lg="8" class="el_form_btn_warp">
                        <el-form-item>
                            <el-button type="primary" @click="submit()">提交</el-button>
                            <el-button type="warning" plain @click="cancel()">取消</el-button>
                            <router-link v-if="source_table && ($check_action(source_table_check_url,'get') || $check_action(source_table_check_url,'set'))" class="el-button float-right el-button--default el-button--primary"
                                         :to="source_table_url">详情
                            </router-link>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>


<script>
    import mixin from '@/mixins/page.js';

    export default {
        mixins: [mixin],
        components: {},
        data() {
            return {
                // 路径
                url_get_obj: "~/api/user/get_obj?",
                url_set: "~/api/user/set?",
                url_upload: "~/api/user/upload?",
                url_user_group_get_obj: "~/api/user_group/get_obj?",
                source_table: null,
                source_table_name: null,
                source_table_check_url: "",
                source_table_url:"",

                // 字段
                field: "user_id",

				oauth: {
					"signIn": true,
					"user_group": []
				},
				
                // 查询
                query: {
                    "user_id": this.$store.state.user.user_id
                },

                // 表单
                form: {
                    user_id: this.$store.state.user.user_id,
                    username: "",
                    phone: "",
                    email: "",
                    phone_state: '',
					email_state: '',
					mz_state: '',
					xz_state: '',
					cy_state: '',
					yd_state: '',
                    price: '',
                    avatar: ""
                },

                list_state: [{
					value: "s",
					name: "s"
				}, {
					value: "m",
					name: "m"
				}, {
					value: "l",
					name: "l"
				},
				{
					value: "xl",
					name: "xl"
				},{
					value: "xxl",
					name: "xxl"
				},{
					value: "xxxl",
					name: "xxxl"
				}],

				list_state1: [{
					value: "36码",
					name: "36码"
				}, {
					value: "37码",
					name: "37码"
				}, {
					value: "38码",
					name: "38码"
				},
				{
					value: "39码",
					name: "39码"
				},{
					value: "40码",
					name: "40码"
				},{
					value: "41码",
					name: "41码"
				},{
					value: "42码",
					name: "42码"
				},{
					value: "43码",
					name: "43码"
				},{
					value: "44码",
					name: "44码"
				}],

				list_state2: [{
					value: "小号",
					name: "小号"
				}, {
					value: "常规尺码",
					name: "常规尺码"
				}, {
					value: "大号",
					name: "大号"
				}],

				list_state3: [{
					value: "110cm",
					name: "110cm"
				}, {
					value: "120cm",
					name: "120cm"
				}, {
					value: "130cm",
					name: "130cm"
				},
				{
					value: "140cm",
					name: "140cm"
				},{
					value: "150cm",
					name: "150cm"
				},{
					value: "160cm",
					name: "160cm"
				}],

				list_state4: [{
					value: "s",
					name: "s"
				}, {
					value: "m",
					name: "m"
				}, {
					value: "l",
					name: "l"
				},
				{
					value: "xl",
					name: "xl"
				},{
					value: "xxl",
					name: "xxl"
				},{
					value: "xxxl",
					name: "xxxl"
				}],

				list_user_state: [{
					value: 1,
					name: "可用"
				}, {
					value: 2,
					name: "异常"
				}, {
					value: 3,
					name: "已冻结"
				}, {
					value: 4,
					name: "已注销"
				}],

                // 校验规则
                // rules: {
                //     nickname: [{
                //             required: true,
                //             message: '警号不能为空！',
                //             trigger: 'blur'
                //         },
                //         {
                //             min: 2,
                //             max: 16,
                //             message: '长度在 2 到 16 个字符',
                //             trigger: 'blur'
                //         }
                //     ],
                //     phone: [{
                //         min: 2,
                //         max: 11,
                //         message: '长度为 11 个字符',
                //         trigger: 'blur'
                //     }]
                // }
            }
        },
        methods: {
            /**
             * 上传封面图
             * @param {Object} param
             */
            uploadAvatar(param) {
                this.uploadFile(param.file, "avatar");
            },

            /**
             * @description 获取用户信息
             * @param {Function} func 回调函数
             */
            async submit_after(json, func){
                this.$get_user(() => {
                    if (this.oauth.signIn) {
                        if (this.user.user_id) {
                            // 执行获取权限并存储
                            this.$get_auth(this.user.user_group)
                            if (func) {
                                func(json);
                            }
                            this.$router.go(-1);
                        } else {
                            this.$router.push({
                                path: "/login"
                            })
                        }
                    } else {
                        if (func) {
                            func(json);
                        }
                        this.$router.go(-1);
                    }
                });
            },
            async get_obj_after(json, func){
                let user_group = this.$store.state.user.user_group;
                var json = await this.$get("~/api/user_group/get_obj?name="+user_group);
                if(json.result && json.result.obj){
                    if (json.result.obj.source_table) {
                        this.source_table = json.result.obj.source_table
                        this.source_table_name = user_group
                        this.source_table_check_url = "/"+this.source_table +"/view";
                        this.source_table_url = this.source_table_check_url+"?user_id=" +this.form.user_id
                    }
                }
            }
        },
        created() {}
    }
</script>


<style>
    #user_info .card {
        padding: 2rem 1rem;
    }

    #user_info .text-center {
        text-align: center;
    }

    #user_info .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    #user_info .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    #user_info .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    #user_info .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
